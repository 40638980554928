import React, { useState, useMemo} from "react";
import Map from "./components/Map";
import SearchBar from "./components/search/SearchBar";
import DesktopDrawer from "./components/DesktopDrawer";

import SwipeableEdgeDrawer from "./components/MobileDrawer";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  useMediaQuery,
  Box,
  IconButton,
} from "@mui/material";

const App = () => {
  /* Set default center position */
  const center = useMemo(
    () => ({ lat: 22.296943839436747, lng: 114.17097964322545 }),
    []
  );

  /* Mobile View Responsive Size at 1024px */
  let isMedium = useMediaQuery("(max-width:1024px)");

  /* Styles */
  const styles = {
    search: {
      position: "absolute",
      zIndex: 1201,
      maxWidth: "400px",
      top: "10px",
      left: "10px",
      right: "10px",
    },
    searchHidden: {
      display: 'none' 
    },
    resultList: {
      backgroundColor: "white",
      position: "absolute",
      zIndex: 1,
      width: "430px",
      height: "100vh",
      boxShadow: 1,
    },
    openBtn: {
      position: "absolute",
      left: "0",
      top: "50vh",
      width: "28px",
      height: "45px",
      textAlign: "center",
      backgroundColor: "white",
      borderBottomRightRadius: "6px",
      borderTopRightRadius: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      boxShadow: 1,
      zIndex: 1,
      ...(isMedium && {
        display: "none",
      }),
    },
    closeBtn: {
      position: "absolute",
      left: "430px",
      top: "50vh",
      width: "28px",
      height: "45px",
      textAlign: "center",
      backgroundColor: "white",
      borderBottomRightRadius: "6px",
      borderTopRightRadius: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      boxShadow: 1,
      zIndex: 1,
      ...(isMedium && {
        display: "none",
      }),
    },
  };

  /* Show side bar state */
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };

  /* Set default value */
  const [currentZoom, setCurrentZoom] = useState(18); //Default Zoom Level
  //const [inputResult, setInputResult] = useState(""); //The input in the search box
  const [isClicked, setIsClicked] = useState(false); //Search item cliced status
  const [childClicked, setChildClicked] = useState(false); // Marker clicked status
  const [searchClicked, setSearchClicked] = useState(false); // Search button cliced status
  const [shopData, setShopData] = useState([]); //Array of searched result
  const [markerData, setMarkerData] = useState([]); // Array of Clicked Marker result
  const [filter, setFilter] = useState([]); // Array of selected District and Shop Type

  const [directionClicked, setDirectionClicked] = useState(false); /* Trigger the direction service of my database status*/
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [selectedMode, setSelectedMode] = useState('');
  const [zeroResult, setZeroResult] = useState(false); 
  const [directionDrawer, setDirectionDrawer] = useState(false);
  const [directionInfo, setDirectionInfo] = useState(false);


  function handleZoomChanged(newZoom) {
    setCurrentZoom(newZoom);
  }

  const onInputChanged = (data) => {
    setShopData(data)
    setChildClicked(false);
  }; 

  const onClickedChanged = (clicked) => {
    setIsClicked(clicked);
    setIsShown(clicked);
  };

  return (
    <>
      {/* Search Bar */}
      {directionDrawer === true ? 
      ( <Box sx={styles.searchHidden}><SearchBar/></Box>) 
      : (
        <Box sx={styles.search}>
        <SearchBar
          onInputChanged={onInputChanged}
          isClicked={isClicked}
          onClickedChanged={onClickedChanged}
          setSearchClicked={setSearchClicked}
          searchClicked={searchClicked}
          filter={filter}
          setFilter={setFilter}
        />
        </Box>
      )}

      {/* Desktop Result List */}
      {isMedium && (isClicked || childClicked || directionDrawer) ? (
        <SwipeableEdgeDrawer 
        shopData={shopData}
        isClicked={isClicked}
        childClicked={childClicked}
        markerData={markerData}
        setDirectionClicked={setDirectionClicked}
        directionsResponse={directionsResponse}
        setDirectionsResponse={setDirectionsResponse}
        setSelectedMode={setSelectedMode}
        zeroResult={zeroResult}
        setZeroResult={setZeroResult}
        directionDrawer={directionDrawer}
        setDirectionDrawer={setDirectionDrawer}
        directionInfo={directionInfo}
        setDirectionInfo={setDirectionInfo}
        />
      ) : (
        (isClicked || childClicked || directionDrawer) ? (
        <Box
          sx={styles.resultList}
          style={{ display: isShown ? "block" : "none" }}
        >
        <DesktopDrawer 
        shopData={shopData}
        isClicked={isClicked}
        childClicked={childClicked}
        markerData={markerData}
        setDirectionClicked={setDirectionClicked}
        directionsResponse={directionsResponse}
        setDirectionsResponse={setDirectionsResponse}
        setSelectedMode={setSelectedMode}
        zeroResult={zeroResult}
        setZeroResult={setZeroResult}
        directionDrawer={directionDrawer}
        setDirectionDrawer={setDirectionDrawer}
        directionInfo={directionInfo}
        setDirectionInfo={setDirectionInfo}
        />
        </Box>
        ):(<></>)
      )}

      {/* Open and Close Button for Desktop Result List */}
      {(isClicked || childClicked) ? (isShown ? (
        <Box sx={styles.closeBtn}>
          <IconButton onClick={handleClick} sx={{ color: "black" }}>
            <ArrowLeftIcon />
          </IconButton>
        </Box>
      ) : (
        <Box sx={styles.openBtn}>
          <IconButton onClick={handleClick} sx={{ color: "black" }}>
            <ArrowRightIcon />
          </IconButton>
        </Box>
      )):(<></>)}

      {/* Google Map */}
      <Map
        center={center}
        zoom={currentZoom}
        onZoomChanged={handleZoomChanged}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
        shopData={shopData}
        setIsShown={setIsShown}
        setMarkerData={setMarkerData}
        childClicked={childClicked}
        setChildClicked={setChildClicked}
        searchClicked={searchClicked}
        directionClicked={directionClicked}
        setDirectionClicked={setDirectionClicked}
        directionsResponse={directionsResponse}
        setDirectionsResponse={setDirectionsResponse}
        selectedMode={selectedMode}
        setZeroResult={setZeroResult}
        setDirectionDrawer={setDirectionDrawer}
        setDirectionInfo={setDirectionInfo}
      ></Map>
    </>
  );
};

export default App;
