import React, { useState } from "react";
import "./Dialog.css"
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormGroup,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Shop Sub Type */
const shopCat0 = [
  {id:'a1', type: '超級市場'},
  {id:'a2', type: '便利店'},
  {id:'a3', type: '士多'},
  {id:'a4', type: '百貨公司 '},
  {id:'a5', type: '街市'},
  {id:'a6', type: '綜合商品零售店'},  
];
const shopCat1 = [
  {id:'b1', type: '麵包西餅店'},
  {id:'b2', type: '中式糕餅店'},
  {id:'b3', type: '中式包點店'},
  {id:'b4', type: '糖果店'},
  {id:'b5', type: '曲奇餅店'},
  {id:'b6', type: '海味店'},
  {id:'b7', type: '冷凍食品店'},
  {id:'b8', type: '魚檔'},
  {id:'b9', type: '蔬果店'},
  {id:'b10', type: '麵粉廠'},
  {id:'b11', type: '香料店'},
  {id:'b12', type: '米行'},
  {id:'b13', type: '豬肉店'},
  {id:'b14', type: '茶葉店'},
  {id:'b15', type: '豆腐店'},
  {id:'b16', type: '其他專門食品零售店'},
  {id:'b17', type: '酒品專賣店'},
  {id:'b18', type: '飲品店'},
  {id:'b19', type: '煙草店'},  
];
const shopCat2 = [
  {id:'c1', type: '酒樓'},
  {id:'c2', type: '中菜館'},
  {id:'c3', type: '粵菜館'},
  {id:'c4', type: '火鍋餐廳'},
  {id:'c5', type: '京川滬餐廳'},
  {id:'c6', type: '港式餐廳'},
  {id:'c7', type: '日式餐館'},
  {id:'c8', type: '韓式餐館'},
  {id:'c9', type: '泰式餐館'},
  {id:'c10', type: '越式餐館'},
  {id:'c11', type: '台式餐廳'},
  {id:'c12', type: '亞洲菜餐廳'},
  {id:'c13', type: '西式餐廳'},
  {id:'c14', type: '法式餐館'},
  {id:'c15', type: '意式餐館'},
  {id:'c16', type: '漢堡餐廳'},
  {id:'c17', type: '多國菜餐廳'},
  {id:'c18', type: '餐廳'},
  {id:'c19', type: '快餐店'},
  {id:'c20', type: '小食店'},
  {id:'c21', type: '美食廣場'},
  {id:'c22', type: '甜品店'},
  {id:'c23', type: '外賣店'},
  {id:'c24', type: '酒吧及酒廊'},
  {id:'c25', type: '咖啡店'},
  {id:'c26', type: '涼茶鋪'},
  {id:'c27', type: '台式飲品店'},
  {id:'c28', type: '飲品店'},
];
const shopCat3 = [
  {id:'d1', type: '鞋品店'},
  {id:'d2', type: '服裝店'},
  {id:'d3', type: '女士服裝店'},
  {id:'d4', type: '時裝及配飾店'},
  {id:'d5', type: '雨傘專門店'},
  {id:'d6', type: '皮革用品店'},
  {id:'d7', type: '手袋店'},
  {id:'d8', type: '行李店'},
  {id:'d9', type: '中藥店'},
  {id:'d10', type: '藥房'},
  {id:'d11', type: '營養補充品店'},
  {id:'d12', type: '醫療用品零售店'},
  {id:'d13', type: '化妝品店'},
  {id:'d14', type: '保健與美容用品店'},
  {id:'d15', type: '汽車經銷商'},
  {id:'d16', type: '電單車經銷商'},
  {id:'d17', type: '二手車經銷商'},
  {id:'d18', type: '汽車零件店'},
  {id:'d19', type: '電單車配件店'},
  {id:'d20', type: '其他運輸設備零售店'},
  {id:'d21', type: '手工藝品店'},
  {id:'d22', type: '珠寶店'},
  {id:'d23', type: '人造珠寶店 '},
  {id:'d24', type: '眼鏡店'},
  {id:'d25', type: '相機專賣店'},
  {id:'d26', type: '背包店'},
  {id:'d27', type: '樂器零售店'},
  {id:'d28', type: '鐘錶零售店 '},
  {id:'d29', type: '紙製品零售店'},
  {id:'d30', type: '宗教用品店'},
  {id:'d31', type: '園藝店'},
  {id:'d32', type: '寵物店'},
  {id:'d33', type: '防火設備零售店'},
  {id:'d34', type: '禮品店'},
  {id:'d35', type: '名牌店'},
  {id:'d36', type: '專業儀器零售店'},
  {id:'d37', type: '辦公室器材及家具店'},
  {id:'d38', type: '商店'},
  {id:'d39', type: '古董店'},
  {id:'d40', type: '集郵店'},
  {id:'d41', type: '二手店'},
  {id:'d42', type: '釣魚用具商店'},  
];
const shopCat4 = [
  {id:'e1', type: '洗滌及乾洗服務'},
  {id:'e2', type: '理髮服務'},
  {id:'e3', type: '化妝、皮膚及面部護理服務'},
  {id:'e4', type: '體重控制及纖體服務'},
  {id:'e5', type: '其他美容及美體護理'},
  {id:'e6', type: '浴室服務'},
  {id:'e7', type: '身體按摩服務'},
  {id:'e8', type: '足底按摩'},
  {id:'e9', type: '算命服務'},
  {id:'e10', type: '交友尋緣和約會服務'},
  {id:'e11', type: '寵物及動物的照顧及馴訓服務'},
  {id:'e12', type: '殮葬及相關活動'},
  {id:'e13', type: '其他雜項個人服務活動'},  
];
const shopCat5 = [
  {id:'f1', type: '酒店'},
  {id:'f2', type: '賓館'},
  {id:'f3', type: '旅舍'},  
];
const shopCat6 = [
  {id:'g1', type: '持牌銀行'},
  {id:'g2', type: '有限制牌照銀行'},
  {id:'g3', type: '投資及控股公司'},
  {id:'g4', type: '當鋪'},
  {id:'g5', type: '外幣兌換店及外匯經紀或交易商'},
  {id:'g6', type: '財務公司'},
  {id:'g7', type: '保險公司'},
];
const shopCat7 = [
  {id:'h1', type: '紡織品商店'},
  {id:'h2', type: '缝纫用品商店'},
  {id:'h3', type: '縫紉材料商店'},
  {id:'h4', type: '刺繡製品商店'},
  {id:'h5', type: '床上用品店'},
  {id:'h6', type: '帳幔零售店'},
  {id:'h7', type: '床褥店'},
  {id:'h8', type: '五金用品店'},
  {id:'h9', type: '地毯店'},
  {id:'h10', type: '籐製品店'},
  {id:'h11', type: '陶瓷店'},
  {id:'h12', type: '玻璃店'},
  {id:'h13', type: '廚房家具店'},
  {id:'h14', type: '窗簾店'},
  {id:'h15', type: '電器零售店'},
  {id:'h16', type: '傢俬店'},
  {id:'h17', type: '家居用品店'},  
];
const shopCat8 = [
  {id:'i1', type: '醫院'},
  {id:'i2', type: '西醫診所'},
  {id:'i3', type: '牙醫診所'},
  {id:'i4', type: '中醫診所'},
  {id:'i5', type: '醫療及X光化驗所'},
  {id:'i6', type: '長者護養院'},
  {id:'i7', type: '其他住宿照顧'},
  {id:'i8', type: '社會服務機構'},
  {id:'i9', type: '青少年服務中心'},
  {id:'i10', type: '嬰兒園'},   
];
const shopCat9 = [
  {id:'j1', type: '地產發展商'},
  {id:'j2', type: '地產代理公司'},
  {id:'j3', type: '物業管理公司'},   
];
const shopCat10 = [
  {id:'k1', type: '創作及表演藝術'},
  {id:'k2', type: '圖書館及檔案保存'},
  {id:'k3', type: '博物館活動及歷史遺址'},
  {id:'k4', type: '動植物園及自然生態保護'},
  {id:'k5', type: '桌球中心'},
  {id:'k6', type: '保齡球中心'},
  {id:'k7', type: '游泳池'},
  {id:'k8', type: '溜冰場'},
  {id:'k9', type: '體操及健身中心'},
  {id:'k10', type: '高爾夫球場'},
  {id:'k11', type: '網球場館'},
  {id:'k12', type: '足球及田徑場'},
  {id:'k13', type: '綜合運動場館'},
  {id:'k14', type: '體育俱樂部'},
  {id:'k15', type: '博彩活動'},
  {id:'k16', type: '夜總會及舞廳'},
  {id:'k17', type: '卡拉OK'},
  {id:'k18', type: '電影院'},
  {id:'k19', type: '遊戲機中心'},
  {id:'k20', type: '其他娛樂活動'},  
];
const shopCat11 = [
  {id:'l1', type: '幼稚園'},
  {id:'l2', type: '小學'},
  {id:'l3', type: '中學'},
  {id:'l4', type: '大學'},
  {id:'l5', type: '大專院校'},
  {id:'l6', type: '其他教育'},  
];
const shopCat12 = [
  {id:'m1', type: '汽車及電單車維修服務'},
  {id:'m2', type: '機械及設備的維修及安裝'},
  {id:'m3', type: '電腦及週邊設備修理'},
  {id:'m4', type: '通訊設備修理'},
  {id:'m5', type: '視聽電子產品修理'},
  {id:'m6', type: '家用器具及庭園設備修理'},
  {id:'m7', type: '鞋類及皮革製品修補'},
  {id:'m8', type: '傢具及室內陳設品修理'},
  {id:'m9', type: '鎖匠服務'},
  {id:'m10', type: '鐘錶修理'},
  {id:'m11', type: '珠寶修理'},
  {id:'m12', type: '其他個人及家庭用品修理'},  
];
const shopCat13 = [
  {id:'n1', type: '文具店'},
  {id:'n2', type: '書報攤'},
  {id:'n3', type: '唱片店'},
  {id:'n4', type: '運動用品店'},
  {id:'n5', type: '玩具店'},
  {id:'n6', type: '遊戲光碟專門店'},
  {id:'n7', type: '賭具零售店'},  
];
const shopCat14 = [
  {id:'o1', type: '工會活動'},
  {id:'o2', type: '宗教組織'},
  {id:'o3', type: '政治組織'},
  {id:'o4', type: '其他會員制組織'},  
];
const shopCat15 = [
  {id:'p1', type: '食品的製造'},
  {id:'p2', type: '飲品的製造'},
  {id:'p3', type: '紡織品的製造'},
  {id:'p4', type: '成衣的製造'},
  {id:'p5', type: '皮革及相關製品的製造'},
  {id:'p6', type: '木材及木製品、水松製品、草編及編結材料製品的製造'},
  {id:'p7', type: '紙及紙製品的製造'},
  {id:'p8', type: '印刷及已儲錄資料媒體的複製'},
  {id:'p9', type: '焦煤和精煉石油產品的製造'},
  {id:'p10', type: '化學品及化學產品的製造'},
  {id:'p11', type: '其他化學產品的製造 '},
  {id:'p12', type: '藥品、醫藥化學劑和植物藥材的製造'},
  {id:'p13', type: '橡膠及塑膠產品的製造'},
  {id:'p14', type: '其他非金屬礦產製品的製造'},
  {id:'p15', type: '基本金屬的製造'},
  {id:'p16', type: '金屬製品的製造'},
  {id:'p17', type: '電腦、電子及光學產品的製造'},
  {id:'p18', type: '電器設備的製造'},
  {id:'p19', type: '其他機械設備的製造 '},
  {id:'p20', type: '傢具的製造'},
  {id:'p21', type: '其他製造業 '},
  {id:'p22', type: '醫療與牙科儀器及用品的製造'},
  {id:'p23', type: '其他產品的製造'},  
];
const shopCat16 = [
  {id:'y1', type: '電訊設備零售店'},
  {id:'y2', type: '電腦及周邊設備零售店'},
  {id:'y3', type: '電腦軟件零售店'},
  {id:'y4', type: '電子產品零售店'},
  {id:'y5', type: '視聽器材零售店'},  
];
const shopCat17 = [
  {id:'q1', type: '律師事務所'},
  {id:'q2', type: '會計師事務所'},
  {id:'q3', type: '公關公司'},
  {id:'q4', type: '商業管理及顧問服務業'},
  {id:'q5', type: '科學研究及發展'},
  {id:'q6', type: '獸醫'},
  {id:'q7', type: '廣告及市場研究'},
  {id:'q8', type: '室內設計'},
  {id:'q9', type: '多媒體、視覺及平面設計'},
  {id:'q10', type: '時裝設計'},
  {id:'q11', type: '工業設計'},
  {id:'q12', type: '其他專門設計'},
  {id:'q13', type: '拍攝服務'},
  {id:'q14', type: '相片沖印及修整'},
  {id:'q15', type: '翻譯及傳譯'},
  {id:'q16', type: '其他專業、科學及技術活動'},  
];
const shopCat18 = [
  {id:'r1', type: '物品租賃業'},
  {id:'r2', type: '人力資源管理'},
  {id:'r3', type: '旅行代理及代訂服務'},
  {id:'r4', type: '辦公室行政及支援'},
  {id:'r5', type: '保安及偵查'},
  {id:'r6', type: '園境護理'},
  {id:'r7', type: '清潔公司'},   
];
const shopCat19 = [
  {id:'s1', type: '廢棄物的收集'},
  {id:'s2', type: '廢棄物的處理及處置'},
  {id:'s3', type: '資源的回收'},  
];
const shopCat20 = [
  {id:'t1', type: '建築工程'},
  {id:'t2', type: '土木工程'},
  {id:'t3', type: '專門建造工程'},    
];
const shopCat21 = [
  {id:'u1', type: '報章雜誌出版商'},
  {id:'u2', type: '書籍出版商'},
  {id:'u3', type: '非書籍出版商'},
  {id:'u4', type: '遊戲發行商'},
  {id:'u5', type: '軟件出版商'},
  {id:'u6', type: '其他出版商'},  
];
const shopCat22 = [
  {id:'v1', type: '製片公司'},
  {id:'v2', type: '錄音室'},
  {id:'v3', type: '音樂出版社'},
  {id:'v4', type: '電台廣播'},
  {id:'v5', type: '電視節目編製及廣播'},
  {id:'v6', type: '電訊網絡營運商'},
  {id:'v7', type: '資訊科技公司'},  
];
const shopCat23 = [
  {id:'w1', type: '公共廁所'},
  {id:'w2', type: '郵箱'},
  {id:'w3', type: '狗糞收集箱'},
  {id:'w4', type: '停車場'},
  {id:'w5', type: '旅遊景點'},
  {id:'w6', type: '自動櫃員機(匯豐 HSBC)'},
  {id:'w7', type: '自動櫃員機(銀通 JETCO)'},
  {id:'w8', type: '電話亭'},
  {id:'w9', type: '的士站'},
  {id:'w10', type: '過海的士站'},
  {id:'w11', type: '港鐵站出入口'},
  {id:'w12', type: '其他公共設施'},  
];
const shopCat24 = [
  {id:'x1', type: '油站'},
  {id:'x2', type: '固定小販攤檔'},
  {id:'x3', type: '冷凍倉'},
  {id:'x4', type: '通用貨倉'},
  {id:'x5', type: '迷你自存倉'},
  {id:'x6', type: '郵政局'},
  {id:'x7', type: '速遞公司'},
  {id:'x8', type: '出口貿易'},
  {id:'x9', type: '進口批發'},
  {id:'x10', type: '批發'},
  {id:'x11', type: '非多年生農作物的種植'},
  {id:'x12', type: '多年生農作物的種植'},
  {id:'x13', type: '植物的繁殖'},
  {id:'x14', type: '家畜的飼養'},
  {id:'x15', type: '飼養家禽及採蛋'},
  {id:'x16', type: '其他動物的飼養'},
  {id:'x17', type: '農牧混合'},
  {id:'x18', type: '農業輔助活動'},
  {id:'x19', type: '狩獵及捕捉活動'},
  {id:'x20', type: '捕魚'},
  {id:'x21', type: '水產養殖'},
];

/* Tab function */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const DialogShop = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);

  /* Change Tab content when clicked */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* Check selected check box */
  const checkBoxHandler = (event) => {
    if (props.filterShop.length < 5){
        if (props.filterShop.includes(event.target.value)) {
           // console.log(event.target.value);
            props.setFilterShop((prevFilter) => {
              return prevFilter.filter((existing) => existing !== event.target.value);
            });
          } else {
            props.setFilterShop((prevFilter) => {
              return [event.target.value, ...prevFilter];
            });
          }
    } else if ((props.filterShop.length === 5)) {
        if (props.filterShop.includes(event.target.value)) {
            //console.log(event.target.value);
            props.setFilterShop((prevFilter) => {
              return prevFilter.filter((existing) => existing !== event.target.value);
            });
          } else {
            alert("請最多選擇5項")
          }
    }
  };

  /* Go back */
  const saveData = () => {
    props.close();
  }

    /* Response the value when clicked a checkbox */
    const getSelected = (district) => {
        if (props.filterShop.includes(district)){
            return true;
        } else{
            return false;
        }
    };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="District Dialog"
      style={{ height: "100%"}}
      fullWidth
      maxWidth="md"
      scroll={'paper'}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ ml: 2 }} variant="h6" component="div">
          商店分類
        </Typography>
      </Toolbar>

    <DialogContent dividers sx={{p:0}}>
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons={false}
        value={value}
        onChange={handleChange}
        aria-label="Shop vertical tabs"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: 100, maxWidth: 100}}
        selectionFollowsFocus
      >
        <Tab label="綜合商品零售店" {...a11yProps(0)} />
        <Tab label="食品零售店" {...a11yProps(1)} />
        <Tab label="餐廳" {...a11yProps(2)} />
        <Tab label="各類商品專門零售店" {...a11yProps(3)} />
        <Tab label="個人服務" {...a11yProps(4)} />
        <Tab label="住宿" {...a11yProps(5)} />
        <Tab label="金融及保險" {...a11yProps(6)} />
        <Tab label="其他家居設備" {...a11yProps(7)} />
        <Tab label="人類保健及社會工作" {...a11yProps(8)} />
        <Tab label="地產" {...a11yProps(9)} />
        <Tab label="藝術、娛樂及康樂活動" {...a11yProps(10)} />
        <Tab label="教育" {...a11yProps(11)} />
        <Tab label="修理" {...a11yProps(12)} />
        <Tab label="文化及康樂商品" {...a11yProps(13)} />
        <Tab label="會員制組織" {...a11yProps(14)} />
        <Tab label="製造" {...a11yProps(15)} />
        <Tab label="資訊及通訊設備" {...a11yProps(16)} />
        <Tab label="專業、科學、廣告及設計" {...a11yProps(17)} />
        <Tab label="行政及支援服務" {...a11yProps(18)} />
        <Tab label="資源回收" {...a11yProps(19)} />
        <Tab label="建造" {...a11yProps(20)} />
        <Tab label="出版" {...a11yProps(21)} />
        <Tab label="拍攝及製作" {...a11yProps(22)} />
        <Tab label="公共設施" {...a11yProps(23)} />
        <Tab label="其他" {...a11yProps(24)} />
      </Tabs>

      <TabPanel value={value} index={0}>
      <FormGroup row>
            {shopCat0.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <FormGroup row>
            {shopCat1.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <FormGroup row>
            {shopCat2.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <FormGroup row>
            {shopCat3.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <FormGroup row>
            {shopCat4.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <FormGroup row>
            {shopCat5.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={6}>
      <FormGroup row>
            {shopCat6.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>

      <TabPanel value={value} index={7}>
      <FormGroup row>
            {shopCat7.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={8}>
      <FormGroup row>
            {shopCat8.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={9}>
      <FormGroup row>
            {shopCat9.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={10}>
      <FormGroup row>
            {shopCat10.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={11}>
      <FormGroup row>
            {shopCat11.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={12}>
      <FormGroup row>
            {shopCat12.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={13}>
      <FormGroup row>
            {shopCat13.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={14}>
      <FormGroup row>
            {shopCat14.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={15}>
      <FormGroup row>
            {shopCat15.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={16}>
      <FormGroup row>
            {shopCat16.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={17}>
      <FormGroup row>
            {shopCat17.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={18}>
      <FormGroup row>
            {shopCat18.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={19}>
      <FormGroup row>
            {shopCat19.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={20}>
      <FormGroup row>
            {shopCat20.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
          </TabPanel>
      <TabPanel value={value} index={21}>
      <FormGroup row>
            {shopCat21.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={22}>
      <FormGroup row>
            {shopCat22.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={23}>
      <FormGroup row>
            {shopCat23.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
      <TabPanel value={value} index={24}>
      <FormGroup row>
            {shopCat24.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.type}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.type)}
                    />
                    <label htmlFor={data.id}>{data.type}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </TabPanel>
    </Box>
    </DialogContent>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <DialogActions>
            <Button onClick={saveData} color="primary" variant="contained">
              儲存 - ({props.filterShop.length})
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogShop;
