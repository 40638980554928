import React, { useState, useRef } from "react";
import SearchResult from "./SearchResult";
import DialogDistrict from "./DialogDistrict";
import DialogShop from "./DialogShop";

import "./SearchBar.css";
import {
  Paper,
  InputBase,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Button,
  Toolbar,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Chip,
  Snackbar,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useTheme, styled } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import data from "../../json/location.json";

/* Chip List item style */
const Litem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

/* Expose MUI Alert style*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SearchBar = (props) => {
  const [selectedItem, setSelectedItem] = useState(-1); /*State for selected item in result list*/
  const [isSelected, setIsSelected] = useState(false); /*Check the result list item is selected or not, if true then result list will not anymore*/

  const [search, setSearch] = useState(""); /* Search box value*/
  const searchData = useRef([]); /*Ref for search data result*/
  const outData = useRef([]);
  const districtData = useRef([]); /* temp store of filterData include in json */
  const shopData = useRef([]); /* temp store of filterShop include in json */

  const [open, setOpen] = useState(false); /*Checking the Filter Dialog is open or not*/
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md")); /* Filter Dialog be full sreen below 900px */
  const [districtDialog, setDistrictDialog] = useState(false);
  const [shopDialog, setShopDialog] = useState(false); 
  const [filterData, setFilterData] = useState([]); /* Array of District Filter */
  const [filterShop, setFilterShop] = useState([]); /* Array of shop sub type Filter */
  const [openAlert, setOpenAlert] = useState(false); /*Checking the Alert snack bar is open or not*/
  
  /* Open Alert Function */
  const handleAlertClick = () => {
    setOpenAlert(true);
  };
  /* Close Alert Function */
  const handleAlertClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  /* Chip delete button for advance searching*/
  const handleDataDelete = (chipToDelete) => () => {
    setFilterData((chips) => chips.filter((chip) => chip !== chipToDelete))
  }
  const handleShopDelete = (chipToDelete) => () => {
    setFilterShop((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  /* Suffle Function for randomly get searchData*/
  function shuffle(array) {
    let currentIndex = array.length, randomIndex;
    
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  /* Filter for advance searching */
  const filterSearch = () =>{
    if(filterData.length !== 0 && filterShop.length === 0){
      searchData.current = data.filter((shop) => filterData.some( v => shop.Address.includes(v)))
    } 
    if(filterShop.length !== 0 && filterData.length === 0){
      searchData.current = data.filter ((shop) => filterShop.some( v => shop.ShopSubType.includes(v)))
    } 
    if(filterData.length !== 0 && filterShop.length !== 0) {
      districtData.current = data.filter (
        (shop) => filterData.some( v => shop.Address.includes(v))
      )
      shopData.current = data.filter (
        (shop) => filterShop.some( v => shop.ShopSubType.includes(v))
      )
      searchData.current = districtData.current.filter(value => shopData.current.includes(value))
    } 
    submitHandler();
  }

  /* Keyboard up, down and enter function */
  const keyDownHandler = (event) => {
    if (event.key === "ArrowUp" && selectedItem > 0) {
      setSelectedItem((prev) => prev - 1);
    } else if (event.key === "ArrowDown" && selectedItem < searchData.current.length - 1
    ) {
      setSelectedItem((prev) => prev + 1);
    } else if (event.key === "Enter" && selectedItem === -1) 
      {
      event.preventDefault();
      submitHandler();
    } else if (event.key === "Enter" && selectedItem >= 0) 
      {
      event.preventDefault();
      const selectedShop = searchData.current[selectedItem]
      setSearch(selectedShop.AltName + ", " + selectedShop.Address);
      searchData.current = selectedShop.AltName + ", " + selectedShop.Address;
      setIsSelected(true);
      setSelectedItem(-1);
      filterFunction(selectedShop.id);
      props.onInputChanged(outData.current);
      props.onClickedChanged(true);
    }
  };

  /*---DisplayList--- Handle the display list only*/
  const searchInputHandler = (event) => {
    setSearch(event.target.value);
    props.setSearchClicked(false);
    if (event.target.value !== "") {
      searchData.current = data.filter(
        (shop) =>
          shop.AltName.toLowerCase().includes(event.target.value.toLowerCase()) ||
          shop.NameEng.toLowerCase().includes(event.target.value.toLowerCase())
          /* shop.Address.toLowerCase().includes(event.target.value.toLowerCase()) */
      );
    }  
    props.onClickedChanged(false);
    setIsSelected(false);
  };

  /* Get the Display List Value after clicked */
  const onSelectedResult = (shopNameAddress, shopId) => {
    setIsSelected(true);
    setSearch(shopNameAddress);
    searchData.current = shopNameAddress;
    submitFunction(shopId);
  };

  /* Submit when selected an item in drop down list */
  const submitFunction = (shopId) => {
    //console.log("clicked!");
    filterFunction(shopId);
    props.onInputChanged(outData.current);
    props.onClickedChanged(true);
  };

  const filterFunction = (shopId) => {
    outData.current = data.filter((shop) => shop.id === shopId);
  };

  /* Submit Button Function */
  const submitHandler = () => {
    if (searchData.current.includes(",")) {
      searchData.current = data.filter(
        (shop) => 
        shop.AltName.toLowerCase() === searchData.current.toLowerCase().slice(0,searchData.current.indexOf(',')) &&
        shop.Address.toLowerCase() === searchData.current.toLowerCase().slice(searchData.current.indexOf(',') +2)
      );
    }
    if (searchData.current.length === 0) {
      handleAlertClick();
      searchData.current = [];
      props.setSearchClicked(false);
      props.onClickedChanged(false);
    } else if (searchData.current.length <= 50){
      props.setSearchClicked(true);
      props.onClickedChanged(false);
    } else if (searchData.current.length > 50){
      shuffle(searchData.current)
      searchData.current = searchData.current.slice(0,50);
      props.setSearchClicked(true);
      props.onClickedChanged(false);
    }
    props.onInputChanged(searchData.current);
  };

  /* Reset Button Function */
  const resetHandler = () => {
    setSearch("");
    setIsSelected(false);
    setSelectedItem(-1);
    searchData.current = [];
    props.onInputChanged(searchData.current);
    props.setSearchClicked(false);
    props.onClickedChanged(false);
  };

  /* Filter Dialog Box */
  const openDialogHandler = () => {
    setOpen(true);
    //console.log(filterData)
    //console.log(filterShop)
  };

  /* Close button in Filter Dialog */
  const closeDialogHandler = () => {
    setOpen(false);
    setFilterData([]);
    setFilterShop([]);
    searchData.current = [];
    props.onInputChanged(searchData.current);
    props.setSearchClicked(false);
    props.onClickedChanged(false);
  };

  /* Clear button in Filter Dialog */
  const clearDialogHandler = () => {
    setFilterData([]);
    setFilterShop([]);
    searchData.current = [];
    props.onInputChanged(searchData.current);
    props.setSearchClicked(false);
    props.onClickedChanged(false);
  };

  /* Search Button in Filter Dialog */
  const searchDialogHandler = () => {
    setOpen(false);
    filterSearch();
  }


  return (
    <>
    {/* Search Bar */}
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            maxWidth: 400,
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="tune" onClick={openDialogHandler}>
            <TuneIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="搜尋地圖"
            inputProps={{ "aria-label": "搜尋地圖" }}
            value={search}
            onChange={searchInputHandler}
            onKeyDown={keyDownHandler}
          />
          {search.length !== 0 ? <ClearIcon onClick={resetHandler} /> : <></>}
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={submitHandler} >
            <SearchIcon/>
          </IconButton>
        </Paper>

        {/* Drop down list */}
        <div className="search_result">
          {!isSelected &&
          search.length !== 0 &&
          searchData.current.length !== 0 &&
          props.searchClicked !== true ? (
            searchData.current.map((result, index) => {
              return (
                <SearchResult key={result.id} result={result} selectedItem={selectedItem} index={index} onSelectedResult={onSelectedResult}/>
              );
            })
          ) : isSelected && search.length !== 0 ? (
            <div></div>
          ) : search !== "" && searchData.current.length === 0 ? (
            <div style={{ padding: "10px 20px" }}>No result is found.</div>
          ) : (
            <div></div>
          )}
        </div>

        {/* Filter Dialog */}
        {open ? (
        <Dialog
          open={open}
          fullScreen={fullScreen}
          onClose={closeDialogHandler}
          style={{ height: "100%"}}
          fullWidth
          maxWidth="sm"
        >
        <List sx={{width:'100%'}}>
          <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={closeDialogHandler}
                  aria-label="close"
                >
                  <CloseIcon/>
                </IconButton>
                <Typography
                  sx={{ ml: 2}}
                  variant="h6"
                  component="div"
                >
                  進階搜尋
                </Typography>
              </Toolbar>

        <DialogContent>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {setDistrictDialog(true)}}>
              <ListItemText primary="地點" />
              <KeyboardArrowRightIcon/>
            </ListItemButton>
          </ListItem>

          {filterData.length !== 0 ? (
          <Paper
          sx={{display:'flex', flexWrap:'wrap', listStyle:'none', p:0.5, m:0}} component="ul" elevation={0}
          >
            {filterData.map((data) => {
              return (
                <Litem key={data}>
                  <Chip label={data} onDelete={handleDataDelete(data)} />
                </Litem>
              )
            })}
          </Paper>
          ):(<></>)}

          <ListItem disablePadding>
            <ListItemButton onClick={() => {setShopDialog(true)}}>
              <ListItemText primary="商店分類" />
              <KeyboardArrowRightIcon/>
            </ListItemButton>
          </ListItem>

          {filterShop.length !== 0 ? (
          <Paper
          sx={{display:'flex', flexWrap:'wrap', listStyle:'none', p:0.5, m:0}} component="ul" elevation={0}
          >
            {filterShop.map((data) => {
              return (
                <Litem key={data}>
                  <Chip label={data} onDelete={handleShopDelete(data)} />
                </Litem>
              )
            })}
          </Paper>
          ):(<></>)}

        </DialogContent>
        </List>

        {filterData.length !== 0 || filterShop.length !== 0 ? (
          <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                <DialogActions>
                  <Button
                    onClick={clearDialogHandler}
                    color="primary"
                    variant="outlined"
                  >
                    清除所有
                  </Button>
                  <Button
                    onClick={searchDialogHandler}
                    color="primary"
                    variant="contained"
                  >
                    立即搜尋
                  </Button>
                  </DialogActions>
                </Grid>
              </Grid>
          ):(<></>)}
              
        </Dialog>
      ) : (
        <></>
      )}

      <DialogDistrict 
      open={districtDialog} 
      close={() => setDistrictDialog(false)} 
      setFilterData={setFilterData}
      filterData={filterData}
      />

      <DialogShop 
      open={shopDialog}
      close={() => setShopDialog(false)}
      setFilterShop={setFilterShop}
      filterShop={filterShop}
      />

      {/* Alert for do data found after filtering */}
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>搜尋不到</Alert>
      </Snackbar>
      </>
  );
};

export default React.memo(SearchBar);
