import React, { useState } from "react";
import "./Dialog.css"
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormGroup,
  Toolbar,
  Typography,
  Box,
  Tab,
  Tabs
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* District Content */
const HKI_DISTRICT = [
  {id:'hk1', district: '堅尼地城'},
  {id:'hk2', district: '石塘咀'},
  {id:'hk3', district: '西營盤'},
  {id:'hk4', district: '上環'},
  {id:'hk5', district: '中環'},
  {id:'hk6', district: '金鐘'},
  {id:'hk7', district: '半山區'},
  {id:'hk8', district: '山頂'},
  {id:'hk9', district: '灣仔'},
  {id:'hk10', district: '銅鑼灣'},
  {id:'hk11', district: '跑馬地'},
  {id:'hk12', district: '大坑'},
  {id:'hk13', district: '掃桿埔'},
  {id:'hk14', district: '渣甸山'},
  {id:'hk15', district: '天后'},
  {id:'hk16', district: '寶馬山'},
  {id:'hk17', district: '北角'},
  {id:'hk18', district: '鰂魚涌'},
  {id:'hk19', district: '西灣河'},
  {id:'hk20', district: '筲箕灣'},
  {id:'hk21', district: '柴灣'},
  {id:'hk22', district: '小西灣'},
  {id:'hk23', district: '薄扶林'},
  {id:'hk24', district: '香港仔'},
  {id:'hk25', district: '鴨脷洲'},
  {id:'hk26', district: '黃竹坑'},
  {id:'hk27', district: '壽臣山'},
  {id:'hk28', district: '淺水灣'},
  {id:'hk29', district: '舂磡角'},
  {id:'hk30', district: '赤柱'},
  {id:'hk31', district: '大潭'},
  {id:'hk32', district: '石澳'},  
];
const KL_DISTRICT = [
  {id:'kl1', district: '尖沙咀'},
  {id:'kl2', district: '油麻地'},
  {id:'kl3', district: '佐敦'},
  {id:'kl4', district: '西九龍'},
  {id:'kl5', district: '京士柏'},
  {id:'kl6', district: '太子'},
  {id:'kl7', district: '旺角'},
  {id:'kl8', district: '大角咀'},
  {id:'kl9', district: '美孚'},
  {id:'kl10', district: '荔枝角'},
  {id:'kl11', district: '長沙灣'},
  {id:'kl12', district: '深水埗'},
  {id:'kl13', district: '石硤尾'},
  {id:'kl14', district: '又一村'},
  {id:'kl15', district: '大窩坪'},
  {id:'kl16', district: '昂船洲'},
  {id:'kl17', district: '紅磡'},
  {id:'kl18', district: '黃埔'},
  {id:'kl19', district: '土瓜灣'},
  {id:'kl20', district: '馬頭角'},
  {id:'kl21', district: '馬頭圍'},
  {id:'kl22', district: '啟德'},
  {id:'kl23', district: '九龍城'},
  {id:'kl24', district: '何文田'},
  {id:'kl25', district: '九龍塘'},
  {id:'kl26', district: '筆架山'},
  {id:'kl27', district: '新蒲崗'},
  {id:'kl28', district: '黃大仙'},
  {id:'kl29', district: '東頭'},
  {id:'kl30', district: '橫頭磡'},
  {id:'kl31', district: '樂富'},
  {id:'kl32', district: '鑽石山'},
  {id:'kl33', district: '慈雲山'},
  {id:'kl34', district: '牛池灣'},
  {id:'kl35', district: '坪石'},
  {id:'kl36', district: '九龍灣'},
  {id:'kl37', district: '牛頭角'},
  {id:'kl38', district: '觀塘'},
  {id:'kl39', district: '秀茂坪'},
  {id:'kl40', district: '藍田'},
  {id:'kl41', district: '油塘'},
  {id:'kl42', district: '鯉魚門'},  
];
const NT_DISTRICT = [
  {id:'nt1', district: '葵涌'},
  {id:'nt2', district: '青衣'},
  {id:'nt3', district: '荃灣'},
  {id:'nt4', district: '欣澳'},
  {id:'nt5', district: '梨木樹'},
  {id:'nt6', district: '汀九'},
  {id:'nt7', district: '深井'},
  {id:'nt8', district: '青龍頭'},
  {id:'nt9', district: '馬灣'},
  {id:'nt10', district: '大欖涌'},
  {id:'nt11', district: '掃管笏'},
  {id:'nt12', district: '屯門'},
  {id:'nt13', district: '藍地'},
  {id:'nt14', district: '洪水橋'},
  {id:'nt15', district: '廈村'},
  {id:'nt16', district: '流浮山'},
  {id:'nt17', district: '天水圍'},
  {id:'nt18', district: '元朗'},
  {id:'nt19', district: '新田'},
  {id:'nt20', district: '落馬洲'},
  {id:'nt21', district: '錦田'},
  {id:'nt22', district: '石崗'},
  {id:'nt23', district: '八鄉'},
  {id:'nt24', district: '粉嶺'},
  {id:'nt25', district: '聯和墟'},
  {id:'nt26', district: '上水'},
  {id:'nt27', district: '石湖墟'},
  {id:'nt28', district: '沙頭角'},
  {id:'nt29', district: '鹿頸'},
  {id:'nt30', district: '烏蛟騰'},
  {id:'nt31', district: '大埔墟'},
  {id:'nt32', district: '大埔'},
  {id:'nt33', district: '大埔滘'},
  {id:'nt34', district: '大尾篤'},
  {id:'nt35', district: '船灣'},
  {id:'nt36', district: '樟木頭'},
  {id:'nt37', district: '企嶺下'},
  {id:'nt38', district: '大圍'},
  {id:'nt39', district: '沙田'},
  {id:'nt40', district: '火炭'},
  {id:'nt41', district: '馬料水'},
  {id:'nt42', district: '烏溪沙'},
  {id:'nt43', district: '馬鞍山'},
  {id:'nt44', district: '清水灣'},
  {id:'nt45', district: '西貢'},
  {id:'nt46', district: '大網仔'},
  {id:'nt47', district: '將軍澳'},
  {id:'nt48', district: '坑口'},
  {id:'nt49', district: '調景嶺'},
  {id:'nt50', district: '馬游塘'},  
];
const IS_DISTRICT = [
  {id:'is1', district: '愉景灣'},
  {id:'is2', district: '東涌'},
  {id:'is3', district: '赤鱲角'},
  {id:'is4', district: '大嶼山'},
  {id:'is5', district: '大澳'},
  {id:'is6', district: '長洲'},
  {id:'is7', district: '坪洲'},
  {id:'is8', district: '南丫島'},
  {id:'is9', district: '蒲苔島'},
];

/* Tab function */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DialogDistrict = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);

  /* Change Tab content when clicked */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* Check selected check box */
  const checkBoxHandler = (event) => {
    if (props.filterData.length < 5){
        if (props.filterData.includes(event.target.value)) {
            //console.log(event.target.value);
            props.setFilterData((prevFilter) => {
              return prevFilter.filter((existing) => existing !== event.target.value);
            });
          } else {
            props.setFilterData((prevFilter) => {
              return [event.target.value, ...prevFilter];
            });
          }
    } else if ((props.filterData.length === 5)) {
        if (props.filterData.includes(event.target.value)) {
            //console.log(event.target.value);
            props.setFilterData((prevFilter) => {
              return prevFilter.filter((existing) => existing !== event.target.value);
            });
          } else {
            alert("請最多選擇5項")
          }
    }
  };

  /* Go back */
  const saveData = () => {
    props.close();
  }

  /* Response the value when clicked a checkbox */
    const getSelected = (district) => {
        if (props.filterData.includes(district)){
            return true;
        } else{
            return false;
        }
    };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="District Dialog"
      style={{ height: "100%"}}
      fullWidth
      maxWidth="md"
      scroll={'paper'}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ ml: 2 }} variant="h6" component="div">
          地點
        </Typography>
      </Toolbar>

    <DialogContent >
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Area tabs" variant="fullWidth">
          <Tab label="香港島" {...a11yProps(0)} />
          <Tab label="九龍" {...a11yProps(1)} />
          <Tab label="新界" {...a11yProps(2)} />
          <Tab label="離島" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
          <FormGroup row>
            {HKI_DISTRICT.map((data) => {
              return (
                <Grid item key={data.id} >
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.district}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.district)}
                    />
                    <label htmlFor={data.id}>{data.district}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
          <FormGroup row>
            {KL_DISTRICT.map((data) => {
              return (
                <Grid item key={data.id}>
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.district}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.district)}
                    />
                    <label htmlFor={data.id}>{data.district}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
          <FormGroup row>
            {NT_DISTRICT.map((data) => {
              return (
                <Grid item key={data.id}>
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.district}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.district)}
                    />
                    <label htmlFor={data.id}>{data.district}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
          <FormGroup row>
            {IS_DISTRICT.map((data) => {
              return (
                <Grid item key={data.id}>
                    <input 
                    type="checkbox" 
                    id={data.id}
                    value={data.district}
                    onChange={checkBoxHandler}
                    checked={getSelected(data.district)}
                    />
                    <label htmlFor={data.id}>{data.district}</label>
                </Grid>
              );
            })}
          </FormGroup>
      </CustomTabPanel>
    </Box>

      </DialogContent>     

      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <DialogActions>
            <Button onClick={saveData} color="primary" variant="contained">
              儲存 - ({props.filterData.length})
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogDistrict;
