/* global google */
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  OverlayView,
  OverlayViewF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import React, { useState, useCallback, useRef, useEffect } from "react";
import loadable from "@loadable/component";
import "./Map.css";

import {
  Fab,
  useMediaQuery
} from "@mui/material";

import NavigationIcon from "@mui/icons-material/Navigation";

import jsonData from "../json/location.json";
//import blackCircle from "../assets/icon/dot.png";

/* Import the marker */
const MarkerCom = loadable(async () => import("./MarkerCom"));
/* Start google map on React JS */
const Map = (props) => {
  const [ libraries ] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  /* Mobile View Responsive Size at 1024px */
  let isMedium = useMediaQuery("(max-width:1024px)");

  const ref = useRef(null);
  const [map, setMap] = useState(null);
  const bounds = useRef([]);
  const [reload, setReload] = useState(false);
  const previousData = useRef(null);

  const startRef = useRef(null);
  const endRef = useRef(null);

  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  /* Marker Label Position */
  const getPixelPositionOffset = (width, height) => ({
    x: 10,
    y: -(height / 2),
  });

  const styles = {
    gpsBtn: {
      position: "absolute",
      zIndex: 1,
      bottom: "25px",
      right: "10px",
    },
    gpsBtn2: {
      position: "absolute",
      zIndex: 1,
      bottom: "150px",
      right: "10px",
    },
    directionBox: {
      position: "absolute",
      borderRadius: 1,
      zIndex: 1,
      top: "70px",
      left: "10px",
      right: "10px",
      bgcolor: 'white',
      maxWidth: 400
    },
    clearBtn: {
      position: "absolute",
      zIndex: 1,
      bottom: "150px",
      left: "10px",
    },
  };

  /* Get the map object */
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);


  /* Get user current location */
  const [status, setStatus] = useState("initial");
  const marker = useRef(null);
  const userLocation = useRef(null);
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      setStatus("loading");
      // Obtain user location data from user's device
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //alert("Browser geolocation success!\n\nlat = " + position.coords.latitude + "\nlng = " + position.coords.longitude);
          userLocation.current = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          startRef.current = userLocation.current;

          /* Remove the marker from the map */
          if (marker.current) {
            marker.current.setMap(null);
          }
          /* Add the marker on the map*/
          marker.current = new google.maps.Marker({
            icon: {
              fillColor: "#4285F4",
              fillOpacity: 1,
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 6,
              strokeColor: "rgb(255,255,255)",
              strokeWeight: 2,
            },
            position: userLocation.current,
            title: "You are here!",
          });
          /* Mark the current location */
          marker.current.setMap(map);

          /* Move the map to where the user is */
          map.setCenter(userLocation.current);
          setStatus("watching");

          /* Tracking the user location */
          navigator.geolocation.watchPosition(
            (position) => {
              /* Record the previous user location */
              const previousCoordinates = userLocation.current;
              userLocation.current = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              startRef.current = userLocation.current;

              /* Calculate the direction */
              const userDirection = getCurrentDirection(
                previousCoordinates,
                userLocation.current
              );

              /* Remove the marker from the map */
              if (marker.current) {
                marker.current.setMap(null);
              }
              /* Add the marker on the map*/
              marker.current = new google.maps.Marker({
                icon: {
                  fillColor: "#4285F4",
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  rotation: userDirection,
                  scale: 6,
                  strokeColor: "rgb(255,255,255)",
                  strokeWeight: 2,
                },
                position: userLocation.current,
                title: "You are here!",
              });
              /* Mark the current location */
              marker.current.setMap(map);
              /* Clear all the marker on the map */
              setReload(false);
            },
            () => {
              window.alert("Unable to retrieve your location")
              console.log("Unable to retrieve your location");
            }
          );
        },
        () => {
          window.alert("Unable to retrieve your location")
          console.log("Unable to retrieve your location");
        }
      );
    }
  };

  /* Get Current Direction */
  function getCurrentDirection(previousCoordinates, currentCoordinates) {
    const diffLat = currentCoordinates.lat - previousCoordinates.lat;
    const diffLng = currentCoordinates.lng - previousCoordinates.lng;
    const anticlockwiseAngleFromEast = convertToDegrees(
      Math.atan2(diffLat, diffLng)
    );
    const clockwiseAngleFromNorth = 90 - anticlockwiseAngleFromEast;
    return clockwiseAngleFromNorth;
    // helper function
    function convertToDegrees(radian) {
      return (radian * 180) / Math.PI;
    }
  }

  const moveToUserLocation = () => {
    map.setCenter(userLocation.current);
  };

  /* Zoom Level */
  function handleZoomChanged() {
    props.onZoomChanged(this.getZoom());
  }

  /* Set Hong Kong bounds */
  const HK_BOUNDS = {
    north: 22.64858904756449,
    south: 22.024942986652363,
    east: 114.60521076982455,
    west: 113.67645218193964,
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  /* Clear the present event listener */
  useEffect(() => {
    if (map) {
      ["pan", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onPan) {
        map.addListener("pan", onPan);
      }
      if (onMapIdle) {
        map.addListener("idle", onMapIdle);
      }
    }
  });

  useEffect(() => {
    const setIsClicked = props.setIsClicked;
    /* Clear all the marker on the map */
    setReload(false);
    /* Disable the clicked onPan after pan to that position */
    setIsClicked(false);
  }, [setReload, props.setIsClicked]);

  /* Pan To the position function */
  const onPan = (location) => {
    if (previousData.current !== props.shopData) {
      var latLng = new google.maps.LatLng(location.lat, location.lng);
      map.panTo(latLng);
      previousData.current = props.shopData;
      //console.log("Different marker");
    } else {
      //console.log("Same marker");
    }
  };

  // Get Map value when idle
  const onMapIdle = () => {
    bounds.current = map.getBounds();
    /* Show all the marker on the map */
    setReload(true);
  };

  /* Function to check the markers in the bounds */
  const inBounds = (lat, lng) => {
    let shopLatLng = new google.maps.LatLng(lat, lng);
    let boundContains = bounds.current.contains(shopLatLng);
    return boundContains;
  };

  /* Trigger after the user drag the map */
  const onDragMapEnd = () => {
    /* Clear all the marker on the map */
    setReload(false);
  };

  /* Trigger Drawer Function */
  const triggerDrawer = (data) => {
    var markerData = [];
    markerData.push(data);
    props.setMarkerData(markerData);
    props.setChildClicked(true);
    props.setIsShown(true);
    endRef.current = {lat: data.lat, lng: data.lng};
  };

  /* Pan to the markers with bounds */
  const searchBounds = (markers) => {
    if (previousData.current !== markers) {
      const searchBounds = new google.maps.LatLngBounds();
      markers.map(item => {
        var position = {lat: item.lat, lng: item.lng};
        searchBounds.extend(position);
        return item.id
      });
      map.fitBounds(searchBounds);
      previousData.current = markers;
      //console.log("Different bounds");
    } else {
      //console.log("Same bounds");
    }
    };

    /* Calculate the direction from userlocation to the search record*/
    const calculateDirection = async() => {
      if (startRef.current === null || endRef.current === null) {
        window.alert("請開啟GPS定位")
        return;
      } else {
        const directionsService = new google.maps.DirectionsService();
        await directionsService.route({
          origin: startRef.current,
          destination: endRef.current,
          travelMode: google.maps.TravelMode[props.selectedMode],
        },(result, status) => {
          if(status === google.maps.DirectionsStatus.OK){
            //console.log("New route")
            props.setZeroResult(false);
            props.setDirectionsResponse(result);
            props.setDirectionDrawer(true);
          } else {
            //console.log("No route")
            props.setZeroResult(true);
            props.setDirectionsResponse(null);
            props.setDirectionDrawer(true);
          }
        });
      }
    }

    const directionPanel = () => {
      //console.log("send info")
      props.setDirectionInfo(true);
    }
    

  return (
    <div className="Map">
      {isLoaded ? (
        <>
          {isMedium && (props.isClicked || props.childClicked) ? (
            status !== "watching" ? (
              <Fab size="medium" sx={styles.gpsBtn2}>
                <NavigationIcon onClick={getUserLocation} />
              </Fab>
            ) : (
              <Fab size="medium" color="primary" sx={styles.gpsBtn2}>
                <NavigationIcon onClick={moveToUserLocation} />
              </Fab>
            )
          ) : (
            status !== "watching" ? (
              <Fab size="medium" sx={styles.gpsBtn}>
                <NavigationIcon onClick={getUserLocation} />
              </Fab>
            ) : (
              <Fab size="medium" color="primary" sx={styles.gpsBtn}>
                <NavigationIcon onClick={moveToUserLocation} />
              </Fab>
            )
          )}

          {/* Google Map */}
          <GoogleMap
            onIdle={onMapIdle}
            onDragEnd={onDragMapEnd}
            mapContainerClassName="map-container"
            options={{
              mapId: "9c0314670326fce6",
              gestureHandling: "greedy",
              disableDefaultUI: true,
              minZoom: 11,
              restriction: {
                latLngBounds: HK_BOUNDS,
                strictBounds: false,
              },
            }}
            zoom={props.zoom}
            center={props.center}
            onZoomChanged={handleZoomChanged}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* All marker display when zoom level >= 20 */}
            {props.zoom >= 20 && props.isClicked === false && props.searchClicked === false
              ? jsonData
                  .filter(
                    (location) => inBounds(location.lat, location.lng) === true
                  )
                  .map((location) => 
                  <MarkerCom 
                  key={location.id} 
                  marker={location} 
                  onTriggerDrawer={triggerDrawer}
                  reload={reload} // Dummy
                  />)
              : <></>}

            {/* Search List clicked marker display */}
            {props.isClicked ? (
              props.shopData.map(
                    (location) => {
                     onPan(location);
                     endRef.current = {lat: location.lat, lng: location.lng};
                      return (<MarkerF
                          key={location.id}
                          position={{ lat: location.lat, lng: location.lng }}
                          visible={true}
                          zIndex={100}
                          onMouseOver={() => handleActiveMarker(location.id)}
                          onMouseOut={() => setActiveMarker(null)}
                        >
                          {activeMarker === location.id ? (
                            <InfoWindowF
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <div>
                                <h3>{location.AltName}</h3>
                                <p>{location.Status}</p>
                                <p>{location.ShopSubType}</p>
                                <p>地址: {location.Address}</p>
                              </div>
                            </InfoWindowF>
                          ) : null}
                          <OverlayViewF
                            position={{
                              lat: location.lat,
                              lng: location.lng,
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset={getPixelPositionOffset}
                          >
                            <div className="redMarker">{location.AltName}</div>
                          </OverlayViewF>
                        </MarkerF>);
                    }
                  ))
              : <></>}

            {/* Search button clicked marker display */}
            {props.searchClicked ? (
            searchBounds(props.shopData),
            props.shopData.map(
                    (location) => {
                      return (<MarkerF
                          key={location.id}
                          position={{ lat: location.lat, lng: location.lng }}
                          visible={true}
                          zIndex={100}
                          onMouseOver={() => handleActiveMarker(location.id)}
                          onMouseOut={() => setActiveMarker(null)}
                          onClick={() => triggerDrawer(location)}
                        >
                          {activeMarker === location.id ? (
                            <InfoWindowF
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <div>
                                <h3>{location.AltName}</h3>
                                <p>{location.Status}</p>
                                <p>{location.ShopSubType}</p>
                                <p>地址: {location.Address}</p>
                              </div>
                            </InfoWindowF>
                          ) : null}
                          <OverlayViewF
                            position={{
                              lat: location.lat,
                              lng: location.lng,
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset={getPixelPositionOffset}
                          >
                            <div className="redMarker">{location.AltName}</div>
                          </OverlayViewF>
                        </MarkerF>);
                    }
                  )
                  )
              : <></>}

        {/* Direction service response */}
        {props.directionsResponse ? (
          directionPanel(),
          <DirectionsRenderer directions={props.directionsResponse}/>
        ):(<></>)}

        {props.directionClicked ? (
            calculateDirection(),
            props.setDirectionClicked(false)
        ):(
        <></>
        )}

          </GoogleMap>
        </>
      ):(<></>)}
    </div>
  );
};

export default React.memo(Map);
