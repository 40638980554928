import React, {useState} from "react";
import {
  DirectionsRenderer,
} from "@react-google-maps/api";

import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Button,
  Typography,
  CardContent,
  CardMedia,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

import DirectionsIcon from "@mui/icons-material/Directions";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import BedtimeIcon from '@mui/icons-material/Bedtime';

import defaultImg from "../assets/img/sample.jpg";

const DesktopDrawer = (props) => {
  const [driveMode, setDriveMode] = useState('contained');
  const [walkMode, setWalkMode] = useState('outlined');
  const [transitMode, setTransitMode] = useState('outlined');

  // Direction and Walking Button is clicked
  const directionHandler = () => {
    props.setDirectionClicked(true);
    props.setSelectedMode("DRIVING");
    setDriveMode('contained')
    setWalkMode('outlined')
    setTransitMode('outlined')
  };

  /* Driving Button is clicked */
  const walkHandler = () => {
    props.setDirectionClicked(true);
    props.setSelectedMode("WALKING");
    setDriveMode('outlined')
    setWalkMode('contained')
    setTransitMode('outlined')
  }

  /* Transit Button is clicked */
  const transitHandler= () => {
    props.setDirectionClicked(true);
    props.setSelectedMode("TRANSIT");
    setDriveMode('outlined')
    setWalkMode('outlined')
    setTransitMode('contained')
  }

  /* Clear Direction data */
  const clearDirection = () => {
    props.setDirectionClicked(false);
    props.setDirectionsResponse(null);
    props.setDirectionDrawer(false);
    props.setDirectionInfo(false);
    props.setSelectedMode('');
    props.setZeroResult(false);
  }

  const safeImgImport = (imgPath) => {
    try {
      let temp = require(`../assets/img/${imgPath}`);
      return temp;
    } catch (error){
      return defaultImg;
    }
  }

  return (
    <>
    <CssBaseline />
    {/* When the direction button is clicked */}
    {props.directionDrawer ? (
    <Card sx={{ border: "none", boxShadow: "none", borderRadius: 0}}>
    <CardContent>
      <Typography variant="h6" component="div">
      路線
      </Typography>
      {props.zeroResult ? (
      <Typography variant="subtitle1" component="div">
      無法取得路線
      </Typography>
      ) : (
      <Typography variant="subtitle1" component="div">
      {props.directionsResponse.routes[0].legs[0].duration.text} ({props.directionsResponse.routes[0].legs[0].distance.text})
      </Typography>
      )}
      <Button
      component="label"
      variant = {driveMode}
      size="small"
      sx={{pointerEvents: "all", mr:1, mt:1}}
      startIcon={<DirectionsCarIcon />}
      onClick={directionHandler}
      >
      開車
      </Button>
      <Button
      component="label"
      variant={walkMode}
      size="small"
      sx={{pointerEvents: "all", mr:1, mt:1}}
      startIcon={<DirectionsWalkIcon />}
      onClick={walkHandler}
      >
      步行
      </Button>
      <Button
      component="label"
      variant={transitMode}
      size="small"
      sx={{pointerEvents: "all", mr:1, mt:1}}
      startIcon={<DirectionsTransitIcon />}
      onClick={transitHandler}
      >
      公交
      </Button>
      <Button
      component="label"
      variant="outlined"
      color="error"
      size="small"
      sx={{pointerEvents: "all", mr:1, mt:1}}
      startIcon={<CloseIcon />}
      onClick={clearDirection}
      >
      清除
      </Button>
        {/* Direction service response */}
        {props.directionsResponse ? (
          <Box>
          <DirectionsRenderer directions={props.directionsResponse} panel={ document.getElementById('panel') }/>
          <div id="panel"></div>
          </Box>
        ):(<></>)}
      </CardContent>
      </Card>
      ) : 
      /* When the single search button is clicked */
      props.isClicked ? (
        (props.shopData.map((location) => (
          <Card key={location.id} sx={{ border: "none", boxShadow: "none", borderRadius: 0, mt:9}}>
          <CardMedia
            sx={{ height: 250 }}
            image={safeImgImport(location.image)}
            title={location.AltName}
          />
          <CardContent>
              <Typography variant="h6" component="div">
                {location.AltName}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {location.NameEng}
              </Typography>
              <Typography gutterBottom variant="subtitle2" component="div">
                {location.ShopSubType}
              </Typography>
    
              <Button
                component="label"
                variant="contained"
                sx={{pointerEvents: "all"}}
                startIcon={<DirectionsIcon />}
                onClick={directionHandler}
              >
                路線
              </Button>
              <List sx={{width: '100%', my:2}} dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={location.Address} />
                </ListItem>
                {location.OfficeHour !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2" sx={{ color: 'success.main' }}>營業時間</Typography>}
                  secondary={<Typography variant="body2">{location.OfficeHour} - {location.ClosedHour}</Typography>}
                  />
                </ListItem>
                </>
                ) : (<></>)}
                {location.CloseDay !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <BedtimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2" sx={{ color: 'error.main' }}>休息日</Typography>}
                  secondary={<Typography variant="body2">{location.CloseDay}</Typography>}
                  />
                </ListItem>
                </>
                ) : (<></>)}
                {location.PhoneNoShop !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={location.PhoneNoShop} />
                </ListItem>
                </>
                ) : (<></>)}
              </List>
          </CardContent>
          </Card>
        )))
      ) : 
      /* When the marker is clicked */
      props.childClicked ? (
        (props.markerData
        .map((location) => (
          <Card key={location.id} sx={{ border: "none", boxShadow: "none", borderRadius: 0, mt:9}}>
          <CardMedia
            sx={{ height: 250 }}
            image={safeImgImport(location.image)}
            title={location.AltName}
          />
          <CardContent>
              <Typography variant="h6" component="div">
                {location.AltName}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {location.NameEng}
              </Typography>
              <Typography gutterBottom variant="subtitle2" component="div">
                {location.ShopSubType}
              </Typography>
    
              <Button
                component="label"
                variant="contained"
                sx={{pointerEvents: "all"}}
                startIcon={<DirectionsIcon />}
                onClick={directionHandler}
              >
                路線
              </Button>
              <List sx={{width: '100%', my:2}} dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={location.Address} />
                </ListItem>
                {location.OfficeHour !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2" sx={{ color: 'success.main' }}>營業時間</Typography>}
                  secondary={<Typography variant="body2">{location.OfficeHour} - {location.ClosedHour}</Typography>}
                  />
                </ListItem>
                </>
                ) : (<></>)}
                {location.CloseDay !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <BedtimeIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2" sx={{ color: 'error.main' }}>休息日</Typography>}
                  secondary={<Typography variant="body2">{location.CloseDay}</Typography>}
                  />
                </ListItem>
                </>
                ) : (<></>)}
                {location.PhoneNoShop !== '' ? (
                <>
                <Divider/>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary={location.PhoneNoShop} />
                </ListItem>
                </>
                ) : (<></>)}
              </List>
          </CardContent>
          </Card>
        )))
        ):(<></>)
    }
    </>
    )
};

export default React.memo(DesktopDrawer);
