import React, { useRef } from "react";

import "./SearchBar.css";

import {
  Divider,
} from "@mui/material";

const SearchResult = (props) => {
  const clickedData = useRef("");

  const onClickHandler = (event) => {
    clickedData.current = props.result.AltName + ", " + props.result.Address;
    props.onSelectedResult(clickedData.current, props.result.id);
  };

  return (
    <>
    <div
      key={props.result.id}
      className={
        props.selectedItem === props.index
          ? "search_suggestion_line active"
          : "search_suggestion_line"
      }
      onClick={onClickHandler}
    >
      <small>
        {props.result.AltName} <br/>
        <span style={{color:"gray"}}>{props.result.Address.replace("/", "")}</span>
      </small>
    </div>
    <Divider/>
    </>
  );
};

export default React.memo(SearchResult);
